<template>
  <div class="bg-gray-900">
    <div class="relative isolate overflow-hidden pt-14">

      <img src="/images/hero.png" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
      <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div class="text-center">
          <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            <!-- Tools for drumming -->
             Coming soon
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss">

</style>